import HomeView from '@/views/HomeView.vue';
import { i18n } from '@/plugins/i18n';
import { RouteRecordRaw } from 'vue-router';

const dashboardRoutes: RouteRecordRaw = {
  path: 'payout',
  component: HomeView,
  redirect: (to) => {
    return `/${to.params.lang}/payout/overview`;
  },
  children: [
    {
      path: 'overview',
      name: 'overview',
      component: () =>
        import(
          /*webpackChunkName: "Overview"*/ '@/views/OverviewStatistics.vue'
        ),
      meta: {
        pageTitle: i18n.global.t('Overview'),
        breadcrumbItems: ['overview'],
      },
    },
    {
      path: 'payouts',
      name: 'payouts',
      component: () =>
        import(/*webpackChunkName: "Payouts"*/ '@/views/PayoutsListing.vue'),
      meta: {
        pageTitle: i18n.global.t('Payouts'),
        breadcrumbItems: ['payouts'],
      },
    },
    {
      path: 'my-tasks',
      name: 'my-tasks',
      component: () =>
        import(/*webpackChunkName: "MyTasks"*/ '@/views/MyTasks.vue'),
      meta: {
        pageTitle: i18n.global.t('My Tasks'),
        breadcrumbItems: ['my-tasks'],
      },
    },
    {
      path: 'beneficiaries',
      name: 'beneficiaries',
      component: () =>
        import(
          /*webpackChunkName: "BeneficiaryListing"*/ '@/views/beneficiary/BeneficiaryListing.vue'
        ),
      meta: {
        pageTitle: i18n.global.t('Beneficiaries'),
        breadcrumbItems: ['beneficiaries'],
      },
    },
    {
      path: 'beneficiaries/create',
      name: 'create-beneficiary',
      component: () =>
        import(
          /*webpackChunkName: "CreateOrEditBeneficiary"*/ '@/views/beneficiary/CreateOrEditBeneficiary.vue'
        ),
      meta: {
        pageTitle: i18n.global.t('Create Beneficiary and Account'),
        breadcrumbItems: ['beneficiaries', 'create-beneficiary'],
      },
    },
    {
      path: 'beneficiaries/:id',
      name: 'edit-beneficiary',
      component: () =>
        import(
          /*webpackChunkName: "CreateOrEditBeneficiary"*/ '@/views/beneficiary/CreateOrEditBeneficiary.vue'
        ),
      meta: {
        pageTitle: i18n.global.t('Edit Beneficiary and Account'),
        breadcrumbItems: ['beneficiaries', 'edit-beneficiary'],
      },
    },
    {
      path: 'reports',
      name: 'reports',
      component: () =>
        import(/*webpackChunkName: "Reports"*/ '@/views/ReportsListing.vue'),
      meta: {
        pageTitle: i18n.global.t('Reports'),
        breadcrumbItems: ['reports'],
      },
    },
    {
      path: 'sources',
      name: 'sources',
      component: () =>
        import(
          /*webpackChunkName: "SourcesListing"*/ '@/views/source/SourcesListing.vue'
        ),
      meta: {
        pageTitle: i18n.global.t('Sources'),
        breadcrumbItems: ['advanced-setup', 'sources'],
      },
    },
    {
      path: 'sources/create',
      name: 'create-source',
      component: () =>
        import(
          /*webpackChunkName: "CreateOrEditSource"*/ '@/views/source/CreateOrEditSource.vue'
        ),
      meta: {
        pageTitle: i18n.global.t('Create Source'),
        breadcrumbItems: ['advanced-setup', 'sources', 'create-source'],
      },
    },
    {
      path: 'sources/:id',
      name: 'edit-source',
      component: () =>
        import(
          /*webpackChunkName: "CreateOrEditSource"*/ '@/views/source/CreateOrEditSource.vue'
        ),
      meta: {
        pageTitle: i18n.global.t('Edit Source'),
        breadcrumbItems: ['advanced-setup', 'sources', 'edit-source'],
      },
    },
    {
      path: 'merchants-account',
      name: 'merchants-account',
      component: () =>
        import(
          /*webpackChunkName: "MerchantsAccountListing"*/ '@/views/merchant/MerchantsAccountListing.vue'
        ),
      meta: {
        pageTitle: i18n.global.t('Merchant Account'),
        breadcrumbItems: ['advanced-setup', 'merchants-account'],
      },
    },
    {
      path: 'merchants-account/create',
      name: 'create-merchant-account',
      component: () =>
        import(
          /*webpackChunkName: "CreateOrEditMerchantAccount"*/ '@/views/merchant/CreateOrEditMerchantAccount.vue'
        ),
      meta: {
        pageTitle: i18n.global.t('Create Merchant Account'),
        breadcrumbItems: [
          'advanced-setup',
          'merchants-account',
          'create-merchant-account',
        ],
      },
    },
    {
      path: 'merchants-account/:id',
      name: 'edit-merchant-account',
      component: () =>
        import(
          /*webpackChunkName: "CreateOrEditMerchantAccount"*/ '@/views/merchant/CreateOrEditMerchantAccount.vue'
        ),
      meta: {
        pageTitle: i18n.global.t('Edit Merchant Account'),
        breadcrumbItems: [
          'advanced-setup',
          'merchants-account',
          'edit-merchant-account',
        ],
      },
    },
    {
      path: 'policies-configuration',
      name: 'policies-configuration',
      component: () =>
        import(
          /*webpackChunkName: "PoliciesConfiguration"*/ '@/views/policy/PoliciesConfiguration.vue'
        ),
      meta: {
        pageTitle: i18n.global.t('Policies Configuration'),
        breadcrumbItems: ['advanced-setup', 'policies-configuration'],
      },
    },
    {
      path: 'execution-rules-schedules',
      name: 'execution-rules-schedules',
      component: () =>
        import(
          /*webpackChunkName: "ExecutionRulesAndScheduleListing"*/ '@/views/rules/ExecutionRulesAndScheduleListing.vue'
        ),
      meta: {
        pageTitle: i18n.global.t('Execution Rules and Schedule'),
        breadcrumbItems: ['advanced-setup', 'execution-rules-schedules'],
      },
    },
    {
      path: 'execution-rules-schedules/create',
      name: 'create-execution-rules-schedule',
      component: () =>
        import(
          /*webpackChunkName: "CreateOrEditExecutionRule"*/ '@/views/rules/CreateOrEditExecutionRule.vue'
        ),
      meta: {
        pageTitle: i18n.global.t('Create Execution Rules and Schedule Form'),
        breadcrumbItems: [
          'advanced-setup',
          'execution-rules-schedules',
          'create-execution-rules-schedule',
        ],
      },
    },
    {
      path: 'execution-rules-schedules/:id',
      name: 'edit-execution-rules-schedules',
      component: () =>
        import(
          /*webpackChunkName: "CreateOrEditExecutionRule"*/ '@/views/rules/CreateOrEditExecutionRule.vue'
        ),
      meta: {
        pageTitle: i18n.global.t('Edit Execution Rules and Schedule Form'),
        breadcrumbItems: [
          'advanced-setup',
          'execution-rules-schedules',
          'edit-execution-rules-schedules',
        ],
      },
    },
  ],
};

export default dashboardRoutes;
