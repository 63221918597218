import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import dashboardRoutes from './dashboardRoutes';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:lang',
    name: 'home',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/HomeView.vue'),
    children: [
      dashboardRoutes,
      {
        path: '',
        name: 'advanced-setup',
        redirect: '/:lang',
        meta: {
          breadcrumbItems: ['advanced-setup'],
          pageTitle: 'Setup',
          disabled: true,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
