import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    token: null as string | null,
    logout: null as (() => void) | null,
  }),
  getters: {
    getToken: (state) => state.token,
    getLogout: (state) => state.logout,
  },
  actions: {
    setToken(val: string) {
      this.token = val;
    },
    setLogout(val: () => void) {
      this.logout = val;
    },
  },
});
